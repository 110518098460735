import styled from 'styled-components';
import Home from '../img/draw.svg';
import Bin from '../img/clear.svg';
import useConditionalEffect from '../hooks/useConditionalEffect';
import { registerDrawHandler, clearLayers, clearBufferLayers, label, device } from '../utils/utils';
import SearchBar from './SearchBar';

const Satellite = ({ drawModeRef, isSatellite, drawMode, setDrawMode, result, setResult, whichMode, setWichMode, setCalculatedResult, calculatedHandler }) => {
  
  useConditionalEffect(() =>
    registerDrawHandler(window.map, window.draw, setResult, setDrawMode, whichMode),
    [drawMode[whichMode]]
  );

  const toggleDrawModeHome = () => {
    calculatedHandler(false);
    setWichMode('home');
    setDrawMode(prev => ({
      ...prev,
      home: prev.home === 'simple_select' ? 'draw_polygon' : 'simple_select',
      area: 'simple_select',
      point: 'simple_select',
    }));
    if (drawModeRef.current && drawMode.home === 'simple_select') {
      drawModeRef.current('simple_select');
      drawModeRef.current('draw_polygon');
    } else {
      drawModeRef.current('simple_select');
    }
  };

  const clearHandler = () => {
    clearLayers();
    clearBufferLayers();
    setDrawMode(prev => ({
      ...prev,
      area: 'simple_select',
    }));
    setResult(null);
    setCalculatedResult(0);
    window.draw.changeMode('simple_select');
    window.draw.deleteAll();
  };
  return (
    <>
      <SatelliteContainer>
        <div className="satellite-controls">
          <div className="search">
            <SearchBar />
          </div>
          <div className='satellite-buttons'>
            <button
              onClick={toggleDrawModeHome}
              className={drawMode.home === 'draw_polygon' ? 'satellite-controls-button active' : 'satellite-controls-button'}
            >
              <span><img className='button-img' src={Home} alt="home" /></span> Draw Asset Contour 
            </button>
          
            <button onClick={clearHandler} className="satellite-controls-button">
              <span><img className='button-img' src={Bin} alt="bin" /></span> Clear 
            </button>
          </div>
        </div>
      </SatelliteContainer>
      {isSatellite && result && (
        <SatelliteContainer>
          <div className="satellite-controls">
            <div className="info">
              <div className="title">Info</div>
              {Object.keys(result).map((item, index) => (
                <div className="item" key={index+item}>
                  <div className="text">{label[item]}: </div>
                    {item === 'area' ? (
                          <span>{Math.round(result[item])} m</span>
                      ) : result[item]
                    }
                </div>
              ))}
            </div>
          </div>
        </SatelliteContainer>
      )}
    </>

  )
}


const SatelliteContainer = styled.div`
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;

  .search {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }

  .satellite-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .satellite-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    jusitfy-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .satellite-controls .satellite-controls-button {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 90%;
    height: 24px;
    color: #fff;
    font-weight: 700;
    background-color: rgba(0, 0, 255, 1);
    
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 100%;
      background-color: rgba(0, 0, 255, 1);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.active {
      background-color: #007bff;
      color: #fff;
      &:hover {
        background-color: #268fff;
      }
    }
  }

  .satellite-controls .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    font-size: 12px;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      justify-content: space-between;

      .text {
        color: rgba(30, 30, 30, 0.5);
      }
    }
  }

  .button-img {
    width: 14px;
  }

  @media ${device.tablet} {
    .satellite-controls .satellite-controls-button {
      height: 40px;
    }
  }

`;

export default Satellite;