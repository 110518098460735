import styled from 'styled-components';
import LogoIcon from '../img/logo.png';
import Contacts from './Contacts';
import {device} from '../utils/utils';


const Header = () => {
  return (
    <StyledHeader>
      <div className="logo-container">
        <img src={LogoIcon} alt="logo" height="80" />
        <h2 className='text'>Defensible <span>space</span> viewer</h2>
      </div>
      <Contacts/>
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  height: 100px;
  width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .text {
      margin: 0;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 700;
      span {
        color: rgba(0, 0, 255, 1);
      }
    }
  }
  @media ${device.laptop} { 
    max-width: 1000px;
  }
  @media ${device.tablet} { 
    max-width: 760px;
    .logo-container {
      img {
        height: 60px;
      }
    }
  }
  @media ${device.mobileL} { 
    align-items: end;
    max-width: 420px;
    .logo-container {
      display: none;
    }
  }
  @media ${device.mobileM} { 
    max-width: 370px;
  }
  @media ${device.mobileS} { 
    max-width: 320px;
  }
`;

export default Header;