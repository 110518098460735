import styled from 'styled-components';
import ContactsButton from './ContactsButton';
import Close from '../img/close.svg';

const ContactModal = ({setIsOpen}) => {

  return (
    <ModalContainer>
      <div className="satellite-modal">
        <button className="modal-close" onClick={() => setIsOpen(false)}>
          <img src={Close} alt="close" />
        </button>
        <h4 className='title'>Did you identify risk drivers <br/> in the <span>blue zones?</span></h4>
        <p className='subtitle'>Contact us for practical next steps</p>
        <ContactsButton
          type={'email'}
          text={'CONTACT'}
          link={'mailto: info@hoze-solutions.com'}
        />
      </div>
    </ModalContainer>

  )
}


const ModalContainer = styled.div`
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;

  .satellite-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    text-align: center;
    .title {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 10px 0;
      span {
        color: rgba(0, 0, 255, 1);
      }
    }
    .subtitle {
      font-size: 12px;
      margin: 0 0 25px 0;
    }
    .modal-close {
      position: absolute;
      background-color: transparent;
      border: none;
      padding: 0;
      top: 10px;
      right: 10px;
      box-shadow: none;
      cursor: pointer;
    }
  }

`;

export default ContactModal;