
import turfContains from '@turf/boolean-contains';
import turfLineSplit from '@turf/line-split';
import turfFlatten from '@turf/flatten';
import turfUnion from '@turf/union';
import turfLength from '@turf/length';
import turfAlong from '@turf/along';
import turfDifference from '@turf/difference';
import turfPolygonToLine from "@turf/polygon-to-line";
import turfBuffer from "@turf/buffer";
import turfBooleanPointInPolygon from "@turf/boolean-point-in-polygon";
import turfCentroid from "@turf/centroid";

export const drawStyles = [
  {
    'id': 'gl-draw-polygon-fill-inactive',
    'type': 'fill',
    'filter': ['all', ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'fill-color': 'rgb(0, 19, 124)',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.5
    }
  },
  {
    'id': 'gl-draw-polygon-fill-active',
    'type': 'fill',
    'filter': ['all', ['==', 'active', 'true'],
      ['==', '$type', 'Polygon']
    ],
    'paint': {
      'fill-color': 'rgb(0, 19, 124)',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.5
    }
  },
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all', ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    }
  },
  {
    'id': 'gl-draw-polygon-stroke-inactive',
    'type': 'line',
    'filter': ['all', ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': 'rgb(0, 19, 124)',
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-polygon-stroke-active',
    'type': 'line',
    'filter': ['all', ['==', 'active', 'true'],
      ['==', '$type', 'Polygon']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-line-inactive',
    'type': 'line',
    'filter': ['all', ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#3bb2d0',
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-line-active',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'LineString'],
      ['==', 'active', 'true']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-inactive',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    }
  },
  {
    'id': 'gl-draw-point-point-stroke-inactive',
    'type': 'circle',
    'filter': ['all', ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': 'rgb(27, 201, 0)'
    }
  },
  {
    'id': 'gl-draw-point-inactive',
    'type': 'circle',
    'filter': ['all', ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': 'rgb(27, 201, 0)'
    }
  },
  {
    'id': 'gl-draw-point-stroke-active',
    'type': 'circle',
    'filter': ['all', ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint']
    ],
    'paint': {
      'circle-radius': 7,
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-point-active',
    'type': 'circle',
    'filter': ['all', ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#fbb03b'
    }
  },
  {
    'id': 'gl-draw-polygon-fill-static',
    'type': 'fill',
    'filter': ['all', ['==', 'mode', 'static'],
      ['==', '$type', 'Polygon']
    ],
    'paint': {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }
  },
  {
    'id': 'gl-draw-polygon-stroke-static',
    'type': 'line',
    'filter': ['all', ['==', 'mode', 'static'],
      ['==', '$type', 'Polygon']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-line-static',
    'type': 'line',
    'filter': ['all', ['==', 'mode', 'static'],
      ['==', '$type', 'LineString']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    'id': 'gl-draw-point-static',
    'type': 'circle',
    'filter': ['all', ['==', 'mode', 'static'],
      ['==', '$type', 'Point']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#404040'
    }
  }
];
export const clearLayers = () => {
  if (window.map.getLayer('line')) window.map.removeLayer('line');
  if (window.map.getLayer('line-outline')) window.map.removeLayer('line-outline');
  if (window.map.getSource('line')) window.map.removeSource('line');
};

export const clearBufferLayers = () => {
  if (window.map.getLayer('buffer-outline-small')) window.map.removeLayer('buffer-outline-small');
  if (window.map.getLayer('buffer-outline-medium')) window.map.removeLayer('buffer-outline-medium');
  if (window.map.getLayer('buffer-outline-large')) window.map.removeLayer('buffer-outline-large');
  if (window.map.getLayer('buffer-small')) window.map.removeLayer('buffer-small');
  if (window.map.getLayer('buffer-medium')) window.map.removeLayer('buffer-medium');
  if (window.map.getLayer('buffer-large')) window.map.removeLayer('buffer-large');
  if (window.map.getSource('buffer-small')) window.map.removeSource('buffer-small');
  if (window.map.getSource('buffer-medium')) window.map.removeSource('buffer-medium');
  if (window.map.getSource('buffer-large')) window.map.removeSource('buffer-large');
};

export const riskLevel = {
  0: 'very low',
  1: 'low',
  2: 'moderate',
  3: 'elevated',
  4: 'high',
  5: 'very high',
};
export const vegetationAreas = [
  {
    id: 1+'#83b760',
    text: 'wood',
    mapColor: '#83b760',
    lineColor: 'rgb(0, 19, 124)',
    satelliteColor: '#5E0000',
  },
  {
    id: 2+'#c7e99e',
    text: 'scrub',
    mapColor: '#c7e99e',
    lineColor: 'rgb(80, 106, 252)',
    satelliteColor: '#B00303',
  },
  {
    id: 3+'#e7f8b0',
    text: 'crop',
    mapColor: '#e7f8b0',
    lineColor: 'rgb(143, 160, 255)',
    satelliteColor: '#E76506',
  },
  {
    id: 4+'#d7f2a8',
    text: 'grass',
    mapColor: '#d7f2a8',
    lineColor: 'rgb(207, 214, 255)',
    satelliteColor: '#DAC507',
  },
  {
    id: 5+'#55C100',
    text: 'other',
    mapColor: 'rgb(255, 255, 255)',
    lineColor: 'rgb(255, 147, 147)',
    satelliteColor: '#55C100',
  },
];

const WATER_VOLUME_MODIFIER = 0.225;

const RUNNING_TIME = {
  wood: 115,
  scrub: 45,
  crop: 15,
  grass: 10,
  other: 0,
};

const SUCCESS_PROBALILITY = {
  wood: 0.65,
  scrub: 0.85,
  crop: 0.95,
  grass: 0.99,
  other: 1.00,
};

export const registerDrawHandler = (map, draw, setResult, setDrawMode, whichMode = 'area') => {

  let wichHandle = null;

  if(whichMode === 'area') {
    wichHandle = handleDrawCreate(map, draw, setResult, setDrawMode);
  }

  if(whichMode === 'home') {
    wichHandle = handleDrawBuffer(map, setResult, setDrawMode);
    map.on('draw.update', wichHandle);
  }

  if(whichMode === 'point') {
    wichHandle = handleDrawPoint(setDrawMode);
  }

  map.on('draw.create', wichHandle);

  return () => {
    map.off('draw.create', wichHandle);
    map.off('draw.update', wichHandle);
  };
};


const handleDrawCreate = (map, draw, setResult, setDrawMode) => (e) => {
  clearLayers();
  clearBufferLayers();

  const landcoverFeatures = map.queryRenderedFeatures({ layers: ['landcover-2'] });
  const line = draw.getMode() === 'draw_polygon' ? turfPolygonToLine(e.features[0]) : e.features[0];
  const lineColor = [
    'match', ['get', 'class'],
    'wood', 'rgb(0, 19, 124)', 
    'scrub', 'rgb(80, 106, 252)', 
    'crop', 'rgb(143, 160, 255)', 
    'grass', 'rgb(207, 214, 255)',
    'rgb(255, 147, 147)',
  ];
  const polygonColor = [
    'match', ['get', 'class'],
    'wood', 'rgb(94, 0, 0)',
    'scrub', 'rgb(176, 3, 3)',
    'crop', 'rgb(231, 101, 6)',
    'grass', 'rgb(218, 197, 7)',
    'rgb(85, 193, 0)',
  ];
  const lineColorFromMode = draw.getMode() === 'draw_polygon' ? polygonColor : lineColor;

  const grouped = landcoverFeatures.reduce((acc, f) => {
    const featureClass = f.properties.class;
    if (!acc[featureClass]) acc[featureClass] = [];
    acc[featureClass].push(f);
    return acc;
  }, {});

  const united = Object.entries(grouped).map(([_, v]) => {
    return v.reduce((acc, f) => acc ? { ...turfUnion(acc, f), properties: f.properties } : f);
  });

  const cropped = united.map((f, _, arr) => {
    const other = arr.filter(o => o.properties.class !== f.properties.class);
    return other.reduce((f, o) => turfDifference(f, o) ?? f, f);
  });

  const splittedLine = cropped.reduce((lines, landcover) => {
    return lines.flatMap(line => {
      if (line.properties.class) return line;
      const splitted = turfLineSplit(line, landcover).features;
      const marked = (splitted.length > 0 ? splitted : [line]).map(f => {
        const polygons = landcover.geometry.type === 'MultiPolygon' ? turfFlatten(landcover).features : [landcover];
        const lineLength = turfLength(f);
        const midPoint = turfAlong(f, lineLength / 2);
        if (polygons.some(p => turfContains(p, midPoint)) && f.properties.class !== 'snow') {
          f.properties.class = landcover.properties.class;
        }
        return f;
      });
      return marked;
    });
  }, [line]);

  const groupedLineDistance = splittedLine.reduce((acc, f) => {
    const featureClass = f.properties.class || 'other';
    if (!acc[featureClass]) acc[featureClass] = 0;
    acc[featureClass] += turfLength(f) * 1000;
    return acc;
  }, {});

  const distance = turfLength(line) * 1000;

  const runningTime = (() => {
    if (groupedLineDistance.wood) return RUNNING_TIME.wood;
    if (groupedLineDistance.scrub) return RUNNING_TIME.scrub;
    if (groupedLineDistance.crop) return RUNNING_TIME.crop;
    if (groupedLineDistance.grass) return RUNNING_TIME.grass;
    return RUNNING_TIME.other;
  })();

  const waterVolume = WATER_VOLUME_MODIFIER * distance * runningTime;

  const successProbability = parseFloat(splittedLine.reduce((acc, f) => {
    const featureClass = f.properties.class || 'other';
    const chunkDistance = turfLength(f) * 1000;
    if (featureClass === 'wood') return acc + (chunkDistance * SUCCESS_PROBALILITY.wood / distance);
    if (featureClass === 'scrub') return acc + (chunkDistance * SUCCESS_PROBALILITY.scrub / distance);
    if (featureClass === 'crop') return acc + (chunkDistance * SUCCESS_PROBALILITY.crop / distance);
    if (featureClass === 'grass') return acc + (chunkDistance * SUCCESS_PROBALILITY.grass / distance);
    return acc + (chunkDistance * SUCCESS_PROBALILITY.other / distance);
  }, 0).toFixed(2));
  const calculateZonePercentage = (groupedLineDistance, totalDistance) => {
    const percentage = {};
  
    for (const [zone, length] of Object.entries(groupedLineDistance)) {
      const zonePercentage = (length / totalDistance) * 100;
      percentage[zone] = zonePercentage;
    }
  
    return percentage;
  };
  
  const calculateZoneScore = (percentage) => {
    const { wood = 0, scrub = 0, crop = 0, grass = 0, other = 0 } = percentage;
    let score = 1;
  
    switch (true) {
      case (wood !== 0 && scrub + wood > 30):
        score = 5;
        break;
      case (scrub > 50 && Math.round((crop + grass + other)) === Math.round((100 - scrub))):
        score = 4;
        break;
      case (scrub > 30 && Math.round((crop + grass + other)) === Math.round((100 - scrub))):
        score = 3;
        break;
      case (crop > 50 && Math.round((grass + other)) === Math.round((100 - crop))):
        score = 2;
        break;
      case (other === 100):
        score = 0;
        break;
    }
  
    return score;
  };
  
  const percentage = calculateZonePercentage(groupedLineDistance, distance);
  const zoneScore = calculateZoneScore(percentage);
  map.addSource('line', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: splittedLine
    },
  });

  map.addLayer({
    id: 'line',
    source: 'line',
    type: 'line',
    layout: { 'line-cap': 'round', 'line-join': 'round' },
    paint: {
      'line-color': '#FFF',
      'line-width': 10,
    },
  });

  map.addLayer({
    id: 'line-outline',
    source: 'line',
    type: 'line',
    layout: { 'line-cap': 'round', 'line-join': 'round' },
    paint: {
      'line-color': lineColorFromMode,
      'line-width': 5,
    },
  });

  draw.deleteAll();
  setDrawMode(prev => ({
    ...prev,
    area: 'simple_select',
  }));
  
  const sortedLineDistance = vegetationAreas.reduce((acc, obj) => {
    if (groupedLineDistance.hasOwnProperty(obj.text)) {
      acc[obj.text] = groupedLineDistance[obj.text];
    }
    return acc;
  }, {});
  
  setResult({ distance, waterVolume, runningTime, successProbability, groupedLineDistance: sortedLineDistance, percentage, zoneScore });
};

const addBuffer = (polygon, distance, id, map) => {
  const buffer = turfBuffer(polygon, distance, { units: 'meters' });
  map.addLayer({
    id: `buffer-${id}`,
    type: 'fill',
    source: {
      type: 'geojson',
      data: buffer,
    },
    paint: {
      'fill-color': 'rgba(40, 175, 252, 0.3)',
      'fill-outline-color': 'rgba(40, 175, 252, 0.5)',
    },
  });
  map.addLayer({
    id: `buffer-outline-${id}`,
    type: 'line',
    source: `buffer-${id}`,
    paint: {
      'line-color': 'rgba(156, 194, 255, 1)',
      'line-width': 2,
    },
  });
  map.moveLayer(`buffer-${id}`, 'gl-draw-polygon-fill-inactive.cold');
  map.moveLayer(`buffer-outline-${id}`, 'gl-draw-polygon-fill-inactive.cold');
};
const handleDrawBuffer = (map, setResult, setDrawMode) => (e) => {
  
  clearBufferLayers();
  const polygon = e.features[0];
    
  addBuffer(polygon, 1.5, 'small', map);
  addBuffer(polygon, 9, 'medium', map);
  addBuffer(polygon, 30, 'large', map);

  const line = turfPolygonToLine(polygon);
  const perimeter = turfLength(line, {units: 'meters'});
  const centroid = turfCentroid(polygon);
  const [longitude, latitude] = centroid.geometry.coordinates;

  setDrawMode(prev => ({
    ...prev,
    home: 'simple_select',
  }));
  setResult({ area: perimeter, latitude, longitude });
};

export const label = {
  area: 'Perimeter length',
  longitude: 'Longitude',
  latitude: 'Latitude',
}

const handleDrawPoint = (setDrawMode) => () => {
  setDrawMode(prev => ({
    ...prev,
    point: 'simple_select',
  }));
};

export function calculatePointsInZones(map, draw) {
  const points = draw.getAll().features.filter(feature => feature.geometry.type === 'Point');
  const buffers = ['buffer-small', 'buffer-medium', 'buffer-large'].map(id => map.getSource(id)?._data);

  if (!buffers) {
    console.log('Буферные зоны не найдены.');
    return;
  }

  let totalValue = 0;

  points.forEach(point => {
    const insideBuffer = buffers.some(buffer => turfBooleanPointInPolygon(point, buffer));

    if (insideBuffer) {
      totalValue = 5;
    }
  });

  return totalValue;
}


export const add3DBuildingsLayer = () => {
  window.map.addLayer({
    'id': '3d-buildings',
    'source': 'composite',
    'source-layer': 'building',
    'filter': ['==', 'extrude', 'true'],
    'type': 'fill-extrusion',
    'minzoom': 15,
    'paint': {
      'fill-extrusion-color': '#0011a8',
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'height'],
      ],
      'fill-extrusion-base': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'min_height'],
      ],
      'fill-extrusion-opacity': 0.6,
    },
  });

  if (window.map.getLayer('gl-draw-polygon-fill-inactive.cold')) {
    window.map.moveLayer('3d-buildings', 'gl-draw-polygon-fill-inactive.cold');
  }
};

const size = {
  mobileS: '321px',
  mobileM: '376px',
  mobileL: '426px',
  tablet: '769px',
  laptop: '1025px',
  laptopL: '1441px',
  desktop: '2561px'
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};