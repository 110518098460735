import styled from 'styled-components';
import {device} from '../utils/utils';

const Divider = ({isMobile = false}) => (
  <StyledDivider $isMobile={isMobile} />
);

const StyledDivider = styled.hr`
  color: rgba(0, 0, 0, 1);
  width: 100%;
  margin: 30px 0;

  @media ${device.desktop} { 
    display: ${props => props.$isMobile && 'none'};
  }
  @media ${device.tablet} { 
    display: ${props => props.$isMobile && 'block'};
  }
`;

export default Divider;