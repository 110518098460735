import styled from 'styled-components';
import {device} from '../utils/utils';

const ColoredText = ({primary, blue, primaryNextLine, secondary, fontSize, lineHeight, color, isHeaderText = false}) => {
  return (
    <StyledText $fontSize={fontSize} $lineHeight={lineHeight} $color={color} $isHeaderText={isHeaderText}>
      <div className='primary-text'>
        {primary} 
        <span className='blue-text'>{blue}</span>
      </div>
      <div className='text-container'>
        <div className='primary-text primary-text--blue'>
          <span className='blue-text'>{blue}</span>
          {primaryNextLine}
        </div>
        <div className='secondary-text'>
          {secondary}
        </div>
      </div>
    </StyledText>
  );
}

const StyledText = styled.div`
  .text-container {
    display: flex;
    align-items: center;
    gap: 20px;
    .primary-text {
      .blue-text {
        display: none;
      }
    }
  }
  .primary-text {
    font-size: ${props => props.$fontSize.desktop || "5.625rem"};
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-transform: uppercase;
    line-height: ${props => props.$lineHeight || "100px"};
    white-space: nowrap;
    .blue-text {
      color: rgba(0, 0, 255, 1);
      margin-left: 30px;
    }
  }

  .primary-text--blue {
    color: ${props => props.$color || "rgba(0, 0, 0, 1)"};
  }
    
  .secondary-text {
    letter-spacing: 2px;
    font-size: 1.063rem;
    line-height: 32px;
    width: 550px;
  }

  @media ${device.laptop} { 
    .primary-text {
      font-size: ${props => props.$fontSize.laptop || "3.75rem"};
      line-height: unset;
    }
    .secondary-text {
      font-size: 0.75rem;
      line-height: 18px;
      width: auto;
    }
  }
  @media ${device.tablet} { 
    .text-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .primary-text {
      white-space: wrap;
      font-size: ${props => props.$fontSize.mobile || "2.25rem"};
      line-height: unset;
      .blue-text {
        margin-left: 15px;
      }
    }
    .secondary-text {
      max-width: 600px;
    }
  }
  @media ${device.mobileL} { 
    ${props => props.$isHeaderText && `
      .text-container {
        .primary-text {
          display: flex;
          gap: 10px;
          .blue-text {
            display: flex;
            margin-left: 0;
          }
        }
      }
      .primary-text {
        .blue-text {
          display: none;
        }
      }
    `}
  }
`;

export default ColoredText;
