import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import ColoredText from './components/ColoredText';
import Divider from './components/Divider';
import ContactsButton from './components/ContactsButton';
import Step from './components/Step';
import Map from './components/Map';
import Footer from './components/Footer';
import StepFaq2 from './img/faq_2.png';
import StepFaq3 from './img/faq_3.png';
import StepFaq3Mobile from './img/faq_3_mobile.png';
import StepScreen from './img/faq_screen.png';
import LogoIcon from './img/logo.png';
import {device} from './utils/utils';

const styles = {
  mainText: {
    fontSize: {
      mobile: '2.325rem',
      laptop: '3.75rem',
      desktop: '5.625rem',
    }
  }, 
  secondaryText: {
    fontSize: {
      mobileM: '2.325rem',
      mobile: '2.325rem',
      laptop: '3.75rem',
      desktop: '5.625rem',
    }
  }
};

const App = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <Header/>
      <Divider isMobile={true}/>
      <Container $isFullScreen={isFullScreen}>
        <section className='main_text'>
          <img src={LogoIcon} alt="logo" height="80" width="140" />
          <ColoredText
            fontSize={styles.mainText.fontSize}
            primary='Defensible'
            blue='space'
            primaryNextLine='viewer'
            secondary=''
            isHeaderText={true}
          />
        </section>
        <section className='map-container'>
          <h2 className='faq_title'>
            Safeguard your assets from wildfires by creating defensible space around them. 
            {windowWidth <= 769 ? ' ' : <br/>}
            Follow <span>these three easy steps</span> to visualize that space in 3D:
          </h2>
          <div className='map_content'>
            <div className='stepper'>
              <Step primary={'1'}> 
                {`Enter your asset's \naddress or coordinates \nin the search bar`}
              </Step>
              <Step primary={'2'}>
                Click on <img src={StepFaq2} alt='draw asset contour' height='35' style={{marginBottom: '-14px'}} />
                {`\nthen click each corner of the asset to outline it. ${windowWidth <= 769 ? 'Click' : 'Double-click'} to complete the contour`}
              </Step>
              <Step primary={'3'}>
                {`To inspect the defensible \nspace in 3D,  ${windowWidth <= 769 ? 'place two \nfingers on the screen \nand' : 'use'}`} 
                {windowWidth <= 769 ? 
                  <img src={StepFaq3Mobile} alt='click' height='35'  style={{marginBottom: '-14px', marginLeft: '-8px'}}/>
                  :
                  <img src={StepFaq3} alt='click' height='35'  style={{marginBottom: '-14px', marginLeft: '-8px'}}/>
                }
              </Step>
              <div style={{fontSize: '1.063rem'}}>
                Expand the view to full screen using the top-right symbol <img src={StepScreen} alt='click' height='35'  style={{marginBottom: '-14px'}}/> on the map 
              </div>
            </div>
            <div className='map'>
              <Map isFullScreen={isFullScreen} handleFullScreen={handleFullScreen}/>
            </div>
          </div>
        </section>
        <section>
          <ColoredText
            fontSize={styles.secondaryText.fontSize}
            lineHeight='100px'
            color={'rgba(0, 0, 255, 1)'}
            primary='Looking for'
            blue='expert'
            primaryNextLine='guidance?'
            secondary={`Our team of specialists is here to help you safeguard \nyour asset with a tailored risk mitigation strategy`}
          />
        </section>
        <div className='container-divider'>
          <div className='divider'>
            <Divider/>
          </div>
          <ContactsButton
            type={'email'}
            text={'CONTACT'}
            link={'mailto: info@hoze-solutions.com'}
          />
          <ContactsButton
            text={'Calendly'}
            link={'https://calendly.com/martin-hofmann-hoze-solutions'}
            style='outline'
          />
        </div>
      </Container> 
      <Divider/>
      <Footer/>
    </>
  );
}


const Container = styled.div`
  padding: 10px 0;
  width: 1200px;
  margin: 0 auto;
  .main_text {
    display: none;
  }
  .container-divider {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 30px 0;
    .divider {
      width: 100%;
    }
  }
  .map-container {
    margin: 0 0 40px 0;
    padding: 20px 0;
    .faq_title {
      letter-spacing: 2px;
      margin-left: 44px;
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 400;
      span {
        color: rgba(0, 0, 255, 1);
      }
    }
    .map_content {
      display: flex;
      .stepper {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 55px;
        padding: 0 44px;
      }
      .map {
        width: ${props => props.$isFullScreen ? 'auto' : '100%'};
      }
    }
  }

  @media ${device.laptop} { 
    padding: 10px 20px;
    max-width: 1000px;
    .map {
      margin: ${props => props.$isFullScreen ? '0' : '40px 0 40px 0'};
    }
    .map-container {
      .map_content {
        .stepper {
          padding: 0;
        }
      }
    }
  }
  @media ${device.tablet} { 
    max-width: 760px;
    .map-container {
      margin: 0;
      padding: 10px 0;
      .faq_title {
        margin-left: 0;
      }
      .map_content {
        flex-direction: column;
        align-items: center;
      }
    }
    .container-divider {
      margin: 20px 0;
    }
  }
  @media ${device.mobileL} { 
    max-width: 420px;
    .main_text {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .map-container {
      .faq_title {
        font-size: 18px;
      }
      .map_content {
        .stepper {
          width: 90%;
          gap: 30px;
        }
      }
    }
    .container-divider {
      flex-direction: column;
      .divider {
        display: none;
      }
    }
  }
  @media ${device.mobileM} { 
    max-width: 370px;
    .map-container {
      .map_content {
        .stepper {
          width: 100%;
        }
      }
    }
  }
  @media ${device.mobileS} { 
    max-width: 320px;
  }
`;

export default App;