import styled from 'styled-components';
import Email from '../img/email.svg';
import Calendar from '../img/calendar.svg';
import {device} from '../utils/utils';

const Contacts = () => {
  return (
    <StyledContacts>
      <div className='contact-item'>
        Contact us: 
        <div className='contact-styled'>
          <img className='email-img' src={Email} alt="email" />
          <a href="mailto: info@hoze-solutions.com"> info@hoze-solutions.com </a>
        </div>
      </div>
      <div className='contact-item'>
        Schedule a meeting: 
        <a href={'https://calendly.com/martin-hofmann-hoze-solutions'} className='contact-styled' target='_blank'>
          <img className='calendar-img' src={Calendar} alt="calendar" />
          Calendly
        </a>
      </div>
    </StyledContacts>
  );
}

const StyledContacts = styled.div`
  .contact-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    line-height: 0.5;
    a {
      color: rgba(0, 0, 255, 1);
      text-decoration: none;
    }
  }
  .email-img {
    height: 15px;
  }
  .contact-styled {
    height: 22px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 255, 1);
    border-radius: 100px;
    padding: 0 5px;
    gap: 5px;
    font-weight: 700;
    color: rgba(0, 0, 255, 1);
    text-transform: uppercase;
    cursor: pointer;
    img {
      height: 15px;
    }
      
    &:hover {
      background-color: rgba(224, 224, 255, 1);
    }
  }

  @media ${device.laptop} { 
    font-size: 12px;
    white-space: nowrap;
  }

  @media ${device.tablet} {
    .contact-styled {
      padding: 0 30px 0 5px;
    }
  }

  @media ${device.mobileM} { 
    .contact-item {
      justify-content: start;
    }
  }
`;

export default Contacts;
