import styled from 'styled-components';

const Step = ({primary, children}) => {
  return (
    <StyledText>
      <div className='primary-text'>
        {primary}/
      </div>
      <div className='secondary-text'>
        {children}
      </div>
    </StyledText>
  );
}

const StyledText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 380px;
  height: 90px;
  white-space: pre-wrap;
  .primary-text {
    display: flex;
    align-items: center;
    font-size: 5.625rem;
    font-weight: 700;
    color: rgba(0, 0, 255, 1);
  }
  .secondary-text {
    font-size: 1.063rem;
    line-height: 23px;
  }
`;

export default Step;
