import styled from 'styled-components';
import LogoIcon from '../img/hzs-logob.png';
import Contacts from './Contacts';
import {device} from '../utils/utils';


const Footer = () => {
  return (
    <StyledFooter>
      <div className='footer_container'>
        <div className="logo-container">
          <div className="logo-text-container">
            <img src={LogoIcon} alt="logo" height="80" />
            <h2 className='text'>Defensible <span>space</span> viewer</h2>
          </div>
        </div>
        <div className="additional-contacts">
          <Contacts/>
          <a href='https://hozesolutions.com/data-protection/' target='_blank'>Privacy policy</a>
        </div>
      </div>
      <span>© HoZe Solutions GmbH</span>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  height: 150px;
  width: 1200px;
  margin: 0 auto 20px auto;
  padding: 0 20px;
  .footer_container {
    height: 120px;
    display: flex;
    justify-content: space-between;
  }
  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .logo-text-container {
      display: flex;
      align-items: center;
      gap: 10px;
      .text {
        margin: 0;
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 700;
        span {
          color: rgba(0, 0, 255, 1);
        }
      }
    }
  }

  .additional-contacts {
    display: flex;
    flex-direction: column;
    justify-content: end;
    a {
      color: rgba(0, 0, 255, 1);
    }
  }

  @media ${device.laptop} { 
    max-width: 1000px;
    .additional-contacts {
      font-size: 12px;
    }
  }

  @media ${device.tablet} { 
    max-width: 760px;
    .logo-container {
      img {
        height: 60px;
      }
    }
    .additional-contacts {
      justify-content: center;
    }
  }
  @media ${device.mobileL} { 
    height: auto;
    max-width: 420px;
    .footer_container {
      height: auto;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 20px;
    }
    .logo-container {
      .logo-text-container {
        .text {
          font-size: 25px;
          width: 200px;
        }
      }
    }
  }
  @media ${device.mobileM} { 
    max-width: 370px;
  }
  @media ${device.mobileS} { 
    max-width: 320px;
  }
`;

export default Footer;