import styled from 'styled-components';
import Email from '../img/white_email.svg';
import Calendar from '../img/calendar.svg';
import {device} from '../utils/utils';


const ContactsButton = ({type, text, link, style = 'fill'}) => {
  return (
    <StyledLink href={link} target={type === 'email' ? "_self" : "_blank"} $style={style}>
      {type === 'email' ? <img className='img' src={Email} alt="icon" /> : <img className='img' src={Calendar} alt="icon" /> }
      {text}
    </StyledLink>
  );
}

const StyledLink = styled.a`
  width: 200px;
  height: 50px;
  border-radius: 100px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  img {
    fill: white;
  }

  ${props => props.$style === 'fill' && `
    background-color: rgba(0, 0, 255, 1);
    color: white;
    border: none;
  `}

  ${props => props.$style === 'outline' && `
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 255, 1);
    border: 2px solid rgba(0, 0, 255, 1);
  `}

  @media ${device.tablet} { 
    width: 250px;
    font-size: 12px;
    img {
      height: 12px;
    }
  }
    
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export default ContactsButton;