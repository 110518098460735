import styled from 'styled-components';
import { Geocoder } from "@mapbox/search-js-react";
import { useState } from 'react';
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';

const theme = {
  variables: {
    colorPrimary: '#000',
    unit: '12px',
    padding: '0',
    borderRadius: '0',
    boxShadow: 'none',
  }
};
const SearchBar = () => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (d) => {
    setInputValue(d);

    const coordinateRegex = /^-?\d{1,3}\.\d+,\s?-?\d{1,3}\.\d+$/;
    if (coordinateRegex.test(d)) {
      const [lat, lng] = d.split(',').map(coord => parseFloat(coord.trim()));
      if (!isNaN(lat) && !isNaN(lng)) {
        window.map.flyTo({ center: [lng, lat], zoom: 14 });
      }
    }
  };

  return (
    <StyledGeocoder>
      <Geocoder
        accessToken={'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A'}
        map={window.map}
        mapboxgl={mapboxgl}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={"Enter address or coordinates"}
        marker
        theme={theme}
      />
    </StyledGeocoder>
  );
}

const StyledGeocoder = styled.div`
  input {
    margin-right: 11px;
    padding: 0 20px 0 40px;
  }
  svg {
    fill: blue;
  }

`;

export default SearchBar;