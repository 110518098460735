import React, { useRef, useEffect, useState } from 'react';
import Satellite from './Satellite';
import styled, { keyframes } from 'styled-components';
import mapboxGl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import useConditionalEffect from '../hooks/useConditionalEffect';
import { drawStyles, clearLayers, clearBufferLayers, registerDrawHandler, riskLevel, vegetationAreas, add3DBuildingsLayer, device } from '../utils/utils';
import Bin from '../img/bin.png';
import Line from '../img/line.png';
import Calculate from '../img/calculate.png';
import Fullscreen from '../img/fullscreen.svg';
import Partscreen from '../img/partscreen.svg';
import ZoomControls from './ZoomControls';
import Watermark from './Watermarks';
import ContactModal from './ContactModal';

const App = ({isFullScreen = false, handleFullScreen}) => {
  const [whichMode, setWichMode] = useState('area');
  const [drawMode, setDrawMode] = useState({
    area: 'simple_select',
    home: 'simple_select',
    point: 'simple_select',
  });
  const [isSatellite, setIsSatellite] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);
  const [result, setResult] = useState(null);
  const [calculatedResult, setCalculatedResult] = useState(0);
  const mapRef = useRef(null);
  const toggleDrawModeRef = useRef(null);
  const clearRef = useRef(null);
  const [bearing, setBearing] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    mapboxGl.accessToken = 'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A';

    const map = new mapboxGl.Map({
      container: mapRef.current || '',
      style: `mapbox://styles/mapbox/light-v10`,
      center: [11.632701, 48.142146],
      zoom: 4,
      interactive: true,
      maxTileCacheSize: 100000,
      refreshExpiredTiles: true,
      preserveDrawingBuffer: true,
    });

    const draw = new MapboxDraw({ 
      displayControlsDefault: false,
      styles: drawStyles
    });

    const scale = new mapboxGl.ScaleControl();

    map.addControl(scale);
    map.addControl(draw);

    const scaleContainer = scale._container;

    scaleContainer.parentNode.removeChild(scaleContainer);
    map.getContainer().appendChild(scaleContainer);
    scaleContainer.style.position = 'absolute';
    scaleContainer.style.bottom = '10px';
    scaleContainer.style.right = '10px';

    map.on('rotate', () => {
      setBearing(map.getBearing());
    });

    window.map = map;
    window.draw = draw;

    map.scrollZoom.disable();
    map.touchZoomRotate.disable();

    map.on('wheel', (event) => {
        if (event.originalEvent.ctrlKey) {
          map.scrollZoom.enable();
        } else {
          map.scrollZoom.disable();
        }
    });

    map.on('style.load', () => {
      map.addLayer({
        id: 'landcover-2',
        source: 'composite',
        'source-layer': 'landcover',
        type: 'fill',
        paint: {
          'fill-color': [
            'match', ['get', 'class'],
            'wood', 'rgba(30, 109, 3, 0.8)',
            'scrub', 'rgba(163, 212, 135, 0.6)',
            'crop', 'rgba(198, 253, 18, 0.6)',
            'grass', 'rgba(178, 227, 150, 0.6)',
            'rgba(0, 0, 0, 0)',
          ],
          'fill-opacity': 0.5,
          'fill-outline-color': 'rgba(0,0,0,0)'
        },
      });
      map.addSource('satellite-source', {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
      });

      map.addLayer({
        id: 'satellite-layer',
        type: 'raster',
        source: 'satellite-source',
        paint: {
          'raster-opacity': 1,
        },
      });
      
      add3DBuildingsLayer();
    });

    toggleDrawModeRef.current = (drawMode) => {
      draw.changeMode(draw.getMode() === 'simple_select' ? drawMode : 'simple_select');
    }

    clearRef.current = () => {
      clearLayers();
      clearBufferLayers();
      setDrawMode(prev => ({
        ...prev,
        area: 'simple_select',
      }));
      setResult(null);
      setCalculatedResult(0);
      setIsCalculated(false);
      draw.changeMode('simple_select');
      draw.deleteAll();
    }
  }, []);

  useEffect(() => {
    window.map.resize();
    if (isFullScreen) {
      document.body.classList.add('no-scroll');
      window.map.on('wheel', () => {
        window.map.scrollZoom.enable();
      });
    } else {
      document.body.classList.remove('no-scroll');
      window.map.on('wheel', (event) => {
        if (event.originalEvent.ctrlKey) {
          window.map.scrollZoom.enable();
        } else {
          window.map.scrollZoom.disable();
        }
      });
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (result !== null) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [result]);
  
  useConditionalEffect(() => 
    registerDrawHandler(window.map, window.draw, setResult, setDrawMode, whichMode), 
    [drawMode[whichMode]]
  );
  const toggleDrawMode = () => {
    setWichMode('area');
    setDrawMode(prev => ({
      ...prev,
      area: prev.area === 'simple_select' ? 'draw_line_string' : 'simple_select',
    }));
    if (toggleDrawModeRef.current) {
      toggleDrawModeRef.current('draw_line_string');
    }
  }
  
  const clear = () => {
    clearRef.current?.();
  }

  const toggleSatellite = () => {
    setIsSatellite(!isSatellite);
    setIsCalculated(false);
    if (!isSatellite) {
      window.map.addSource('satellite-source', {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
      });

      window.map.addLayer({
        id: 'satellite-layer',
        type: 'raster',
        source: 'satellite-source',
        paint: {
          'raster-opacity': 1,
        },
      });
      
      add3DBuildingsLayer();
      window.map.moveLayer('3d-buildings', 'satellite-layer');
    } else {
      window.map.removeLayer('3d-buildings');
      window.map.removeLayer('satellite-layer');
      window.map.removeSource('satellite-source');
    }
    if (window.map.getLayer('satellite-layer')) {
      window.map.moveLayer('satellite-layer', 'gl-draw-polygon-fill-inactive.cold');
    }
  };

  const calculatedHandler = (isOpen) => {
    setIsCalculated(isOpen && result ? true : false);
  };

  return (
    <StyledMap $isFullScreen={isFullScreen}>
      <div id="map" ref={mapRef} />
      <div className="panel">
        {!isSatellite && (
          <>
            <div className="controls">
              <button onClick={toggleDrawMode} className={drawMode.area === 'draw_line_string' ? 'active' : ''}>
                {drawMode.area === 'simple_select' ? 'Enable' : 'Disable'} Draw Mode <img className='button-img' src={Line} alt="line" />
              </button>
              <button onClick={clear}>
                Clear <img className='button-img' src={Bin} alt="bin" />
              </button>
              <button onClick={() => calculatedHandler(true)} className="satellite-controls-button">
                Calculate <img className='button-img' src={Calculate} alt="calculator" />
              </button>
            </div>
            <div className="legend">
              <div className="body">
                {vegetationAreas.map((area) => (
                  <div className="item" key={area.id}>
                    <div className="text">{area.text}</div>
                    <div className="colors">
                      <div className="color" style={{ backgroundColor: area.mapColor }}></div>
                      <div className="color" style={{ backgroundColor: area.lineColor }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <div className='switch-to-satellite'>
          {false && 
            <button onClick={toggleSatellite} className="satellite-button">
              Switch to {isSatellite ? 'Vegetation' : 'Satellite'} View
            </button>
          }
          <Satellite 
            drawModeRef={toggleDrawModeRef}
            isSatellite={isSatellite}
            drawMode={drawMode}
            setDrawMode={setDrawMode}
            result={result}
            setResult={setResult}
            whichMode={whichMode}
            setWichMode={setWichMode}
            setCalculatedResult={setCalculatedResult}
            calculatedHandler={calculatedHandler}
          />
        </div>
      </div>
      {isCalculated && result && (
        <div className="result">
          {isSatellite && isCalculated && (!!calculatedResult || result) && (
            <>
              <div className="item">
                The overall risk indicator of your asset based on the analysed drivers is evaluated as:
              </div>
              <div className="item item--zoneScore">
                {riskLevel[calculatedResult || result?.zoneScore]} ({calculatedResult || result?.zoneScore}/5)
              </div>
            </>
          )}
          {isSatellite && isCalculated && result && (calculatedResult || result?.zoneScore >= 2) && (
            <div className="item">
              We identify a risk reduction potential towards a low risk exposure.
            </div>
          )}
          {isSatellite && isCalculated && result && (
            <div className="item">
              We recommend a minimal water volume of {Math.round(result?.waterVolume)} liters.
            </div>
          )}
          <div className="item item--zone"><div style={{minWidth: '73%'}}>Perimeter length analysed:</div> <div>{Math.round(result.distance)} meters</div></div>
          {/* <div className="item"><div>Water volume required:</div> <div>{Math.round(result.waterVolume)} liters</div></div> */}
          {!isSatellite && (
            <>
              <div className="item"><div>Running time required:</div> <div>{result.runningTime} minutes</div></div>
              <div className="item"><div>Success probability:</div> <div>{result.successProbability * 100 + '%'}</div></div>
            </>
          )}
          {Object.keys(result.groupedLineDistance).length > 0 && (
            <div className="item-group">
              {Object.entries(result.groupedLineDistance).map(([k, v]) => (
                <div className="item item--zone" key={k}>
                  <div>{k.charAt(0).toUpperCase() + k.slice(1)} distance:</div> <div>{Math.round(v)} meters / {Math.round(result.percentage[k])}%</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className='fullscreen-button' onClick={handleFullScreen}>
        {isFullScreen ? (
          <img src={Partscreen} alt="fullscreen" />
        ) : (
          <img src={Fullscreen} alt="fullscreen" />
        )}
      </div>
      <div className='zoom-controls'>
        <ZoomControls bearing={bearing} />
      </div>
      {isOpen && (
        <div className='contact-modal'>
          <ContactModal setIsOpen={setIsOpen} />
        </div>
      )}
      <Watermark></Watermark>
    </StyledMap>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledMap = styled.div`

  border-radius: 20px;
  ${props => props.$isFullScreen ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: inherit;
  ` : `
    position: relative;
    height: 600px;
    width: 100%;
  `}
  
  overflow: hidden;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;

  #map {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .panel, .result {
    position: absolute;
    top: 30px;
    font-size: 14px;
    width: 263px;
    z-index: 2;
  }

  .panel {
    left: 30px;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .result {
    right: 30px;
    max-width: 350px;

    .item-group {
      margin-top: 10px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      & > div:first-child {
        margin-right: 5px;
      }
      
      &--zone {
        margin-bottom: 5px;
      }

      &--zoneScore {
        align-self: center;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .controls, .result, .legend {
    display: flex;
    flex-direction: column;
    padding: 10px;
    backdrop-filter: blur(8px);
    background: rgb(238 238 238 / 70%);
    border: 1px solid #f6f8fb;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px rgb(50 50 71 / 8%), 0 4px 8px rgb(50 50 71 / 6%);
  }

  .legend {

    .body {
      border-radius: 8px;
      padding: 5px 10px;
      background-color: #f4f4f4;
      border: 0.5px solid #dcdcdc;
      box-shadow: 0 4px 4px rgb(50 50 71 / 8%), 0 4px 8px rgb(50 50 71 / 6%);
    }

    .item {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .colors {
        display: flex;
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        border: 1px solid #b1b1b1;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }

    }

  }

  .switch-to-satellite {
    button {
      background-color: #f7f7f7;
      border: 1px solid rgba(0, 0, 255, 1);
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 0;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }

    .satellite-button {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
    }
  }

  .controls {
    align-items: center;
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 7px;
      outline: none;
      cursor: pointer;
      background-color: #f4f4f4;
      border: 0.5px solid #dcdcdc;
      box-shadow: 0 4px 4px rgb(50 50 71 / 8%), 0 4px 8px rgb(50 50 71 / 6%);
      transition: background-color 0.2s linear;
      width: 200px;

      img {
        width: 20px
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover:not(.active) {
        background-color: #fff;
      }

      &.active {
        background-color: #007bff;
        color: #fff;

        &:hover {
          background-color: #268fff;
        }
      }
    }
  }

  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-bottom-left {
    display: none;
  }
  .fullscreen-button {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 2;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .zoom-controls {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 2;
  }

  .contact-modal {
    width: 263px;
    position: absolute;
    bottom: 20px;
    left: 30px;
    z-index: 2;
    animation: ${fadeIn} 0.3s forwards;
  }

  @media ${device.mobileL} {
    .panel, .result {
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .fullscreen-button {
      top: auto;
      bottom: 40px;
      right: 20px;
    }
    .zoom-controls {
      display: none;
    }
  }
`;

export default App;