import styled from 'styled-components';
import Panorame from '../img/panorame.svg';
import Plus from '../img/plus.svg';
import Minus from '../img/minus.svg';


function ZoomControls({ bearing }) {

  const handleZoomIn = () => {
    window.map.zoomIn();
  };

  const handleZoomOut = () => {
    window.map.zoomOut();
  };

  const handleResetNorth = () => {
    window.map.resetNorth();
  };

  return (
    <ZoomStyles>
      <button className='rotate' onClick={handleResetNorth}>
        <img src={Panorame} alt="Panorame" style={{ transform: `rotate(${-bearing+45}deg)` }} />
      </button>
      <div className='zoom'>
        <button className='zoom-in' onClick={handleZoomIn}>
          <img src={Plus} alt="Zoom In" />
        </button>
        <button className='zoom-out' onClick={handleZoomOut}>
          <img src={Minus} alt="Zoom Out" />
        </button>
      </div>
    </ZoomStyles>
  );
}

const ZoomStyles = styled.div`
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: rgba(0, 0, 255, 1);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #f5f5f5;
    }
  }
    
  .rotate {
    margin-bottom: 5px;

    img {
      margin-top: 2px;
      height: 16px;
    }
  }
  .zoom {
    margin: 0;
    display: flex;
    flex-direction: column;
    img {
      width: 18px;
    }
    .zoom-in {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .zoom-out {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export default ZoomControls;