import React from 'react';
import styled from 'styled-components';


const Watermark = ({ children }) => (
  <WatermarkContainer>
    <WatermarkContent>{children}</WatermarkContent>
    <WatermarkBackground />
  </WatermarkContainer>
);

const WatermarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
`;

const WatermarkContent = styled.div`
  position: relative;
  z-index: 1;
`;

const WatermarkBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%22200%22%20height%3D%22200%22%3E%3Ctext%20x%3D%220%22%20y%3D%22100%22%20fill%3D%22rgba(0%2C0%2C255%2C1)%22%20font-size%3D%2220%22%20font-family%3D%22Arial%22%20font-weight%3D%22bold%22%20transform%3D%22rotate(-45%2C%20100%2C%20100)%22%3E%C2%A9HoZe%20Solutions%20GmbH%3C%2Ftext%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  background-size: 200px 200px;
  opacity: 0.4;
  pointer-events: none;
`;

export default Watermark;
