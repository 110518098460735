import { useRef, useEffect } from 'react';

export default function useConditionalEffect(callback, dependencies) {
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (hasMountedRef.current) {
      const cleanup = callback();
      return () => cleanup();
    } else {
      hasMountedRef.current = true;
    }
  }, dependencies);
}
